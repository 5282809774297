// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Projects from './pages/Projects';
import Contact from './pages/Contact';

const App = () => {
  const styles = {
    app: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      fontFamily: "'Courier New', Courier, monospace",
      backgroundColor: '#e0e0e0',
      color: '#333',
    },
    content: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0',
      width: '100%',
    },
    placeholderContainer: {
      textAlign: 'center',
      padding: '50px',
      fontFamily: "'Courier New', Courier, monospace",
      color: '#333',
    },
    heading: {
      fontSize: '24px',
      marginBottom: '20px',
    },
    paragraph: {
      fontSize: '18px',
    },
  };

  return (
    <Router>
      <div style={styles.app}>
        {/* Navbar removed */}
        <div style={styles.content}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/contact" element={<Contact />} />
            {/* Fallback route */}
            <Route
              path="*"
              element={
                <div style={styles.placeholderContainer}>
                  <h2 style={styles.heading}>404 - Page Not Found</h2>
                  <p style={styles.paragraph}>
                    The page you're looking for does not exist.
                  </p>
                </div>
              }
            />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;