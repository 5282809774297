// src/pages/Home.js
import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/logo.svg'; // Adjust the path as needed

const Home = () => {
  const [elements, setElements] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Handler to update isMobile state on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Memoize the generateRandomElements function
  const generateRandomElements = useCallback(() => {
    const words = ['HANDYCO', 'ABOUT', 'PROJECTS', 'CONTACT'];
    const wordLinks = {
      HANDYCO: '/',
      ABOUT: '/about',
      PROJECTS: '/projects',
      CONTACT: '/contact',
    };
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#^&*()_+,./ ';
    const blockSize = isMobile ? 200 : 500;
    const charsPerLine = isMobile ? 20 : 60;
    const totalLines = Math.ceil(blockSize / charsPerLine);

    // Introduce maxWordLines to limit word placement on mobile
    const maxWordLines = isMobile ? 5 : totalLines;

    const result = Array(blockSize).fill(null);
    const occupied = Array(totalLines)
      .fill(0)
      .map(() => Array(charsPerLine).fill(false));

    // Shuffle words to randomize their placement order
    const shuffledWords = [...words].sort(() => Math.random() - 0.5);

    // Function to find a random position for a word
    const placeWord = (word) => {
      const wordLength = word.length;
      const possibleLines = [];

      // Identify lines that have enough space for the word
      for (let line = 0; line < maxWordLines; line++) { // Changed from 'totalLines' to 'maxWordLines'
        if (wordLength > charsPerLine) continue; // Skip if word is longer than line
        possibleLines.push(line);
      }

      if (possibleLines.length === 0) return false; // No possible placement

      // Shuffle lines to randomize placement
      const shuffledLines = [...possibleLines].sort(() => Math.random() - 0.5);

      for (const line of shuffledLines) {
        // Find all possible starting indices in the line
        const possibleStarts = [];
        for (let start = 0; start <= charsPerLine - wordLength; start++) {
          let canPlace = true;
          for (let i = 0; i < wordLength; i++) {
            if (occupied[line][start + i]) {
              canPlace = false;
              break;
            }
          }
          if (canPlace) {
            possibleStarts.push(start);
          }
        }

        if (possibleStarts.length === 0) continue; // No space in this line

        // Choose a random starting position from possible starts
        const startPos =
          possibleStarts[Math.floor(Math.random() * possibleStarts.length)];

        // Mark positions as occupied
        for (let i = 0; i < wordLength; i++) {
          occupied[line][startPos + i] = true;
        }

        // Calculate the absolute position
        const absolutePosition = line * charsPerLine + startPos;

        // Push the word element
        result[absolutePosition] = (
          <Link
            key={absolutePosition}
            to={wordLinks[word]}
            style={{
              color: '#666',
              textDecoration: 'none',
              fontWeight: '900',
              textShadow: 'inset 0 1px 3px rgba(0,0,0,0.3)',
              fontSize: isMobile ? '17px' : '17px', // Same size; adjust if needed
              transition: 'filter 0.3s ease', // Smooth transition
              whiteSpace: 'nowrap', // Prevent word from breaking
            }}
            onMouseEnter={(e) => (e.target.style.filter = 'blur(2px)')} // Apply blur on hover
            onMouseLeave={(e) => (e.target.style.filter = 'none')} // Remove blur on mouse leave
          >
            {word}
          </Link>
        );

        // Fill in the rest of the word's characters as empty strings to skip during rendering
        for (let i = 1; i < wordLength; i++) {
          const pos = absolutePosition + i;
          if (pos < blockSize) {
            result[pos] = ''; // Use empty string instead of null
          }
        }

        return true; // Successfully placed the word
      }

      return false; // Failed to place the word
    };

    // Place all words
    shuffledWords.forEach((word) => {
      const placed = placeWord(word);
      if (!placed) {
        console.warn(`Could not place the word: ${word}`);
      }
    });

    // Fill the remaining positions with random characters
    for (let i = 0; i < blockSize; i++) {
      if (result[i] === null) {
        const char = randomChars[Math.floor(Math.random() * randomChars.length)];
        result[i] = (
          <span
            key={i}
            style={{ fontSize: isMobile ? '17px' : '17px' }} // Same size; adjust if needed
          >
            {char}
          </span>
        );
      }
    }

    return result;
  }, [isMobile]);

  // Update elements whenever isMobile changes
  useEffect(() => {
    setElements(generateRandomElements());
  }, [generateRandomElements]);

  const styles = {
    container: {
      textAlign: 'left',
      width: '100%',
      maxWidth: '1000px',
      margin: '0 auto',
      padding: isMobile ? '10px' : '25px', // Adjusted padding
      boxSizing: 'border-box',
      backgroundColor: '#e0e0e0', // Ensure background matches
      minHeight: '100vh', // Ensure the container takes full height
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: isMobile ? 'flex-start' : 'center', // Align to top on mobile
      overflow: 'hidden', // Prevent overflow
      whiteSpace: 'pre-wrap', // Preserve whitespace for proper layout
      fontFamily: "'Courier New', Courier, monospace", // Use a monospace font for alignment
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginBottom: isMobile ? '15px' : '25px', // Adjust margin based on device
      paddingLeft: 0,
      marginLeft: 0,
    },
    logo: {
      width: isMobile ? '50px' : '60px', // Smaller logo on mobile
      height: isMobile ? '50px' : '60px',
      marginRight: isMobile ? '15px' : '25px',
      marginLeft: isMobile ? '-10px' : '-15px',
      marginBottom: '-5px',
      paddingLeft: 0,
      fill: '#666',
      color: '#666',
      display: 'block',
    },
    textBlock: {
      fontSize: isMobile ? '15px' : '17px', // Reduced by 2px on mobile
      textAlign: 'justify',
      lineHeight: '2.25',
      letterSpacing: '6.25px',
      whiteSpace: 'pre-wrap', // Preserve whitespace for proper layout
      wordBreak: 'break-all', // Allows words to break to avoid overflow
      color: '#999',
      marginBottom: isMobile ? '15px' : '25px', // Adjust margin based on device
      paddingLeft: 0,
      fontFamily: "'Courier New', Courier, monospace", // Use a monospace font for alignment
    },
    tagline: {
      marginTop: isMobile ? '10px' : '25px', // Adjust margin based on device
      fontSize: isMobile ? '17px' : '17px', // Same size; adjust if needed
      fontWeight: '600',
      letterSpacing: '1.25px',
      color: '#666',
      textAlign: 'left',
      paddingLeft: 0,
      whiteSpace: 'nowrap', // Prevent tagline from breaking
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <Logo style={styles.logo} />
      </div>
      <div style={styles.textBlock}>
        {elements.map((element, index) => (
          <React.Fragment key={index}>{element}</React.Fragment>
        ))}
      </div>
      <div style={styles.tagline}>ENGINEERING TOMORROW.</div>
    </div>
  );
};

export default Home;