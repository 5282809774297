// src/pages/Contact.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/logo.svg'; // Adjust the path as needed

const Contact = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Handler to update isMobile state on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = {
    container: {
      textAlign: 'left',
      width: '100%',
      maxWidth: '1000px',
      margin: '0 auto',
      padding: isMobile ? '10px' : '25px', // Adjusted padding
      boxSizing: 'border-box',
      backgroundColor: '#e0e0e0', // Ensure background matches
      minHeight: '100vh', // Ensure the container takes full height
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: isMobile ? 'flex-start' : 'center', // Align to top on mobile
      overflow: 'hidden', // Prevent overflow
      whiteSpace: 'pre-wrap', // Preserve whitespace for proper layout
      fontFamily: 'monospace', // Use a monospace font for alignment
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginBottom: isMobile ? '15px' : '25px', // Adjust margin based on device
      paddingLeft: 0,
      marginLeft: 0,
    },
    contactText: {
      fontSize: isMobile ? '15px' : '17px', // Reduced by 2px on mobile
      fontWeight: 'bold',
      letterSpacing: '1.25px',
      color: '#666',
      textAlign: 'left',
    },
    textBlock: {
      fontSize: isMobile ? '15px' : '17px', // Reduced by 2px on mobile
      textAlign: 'justify',
      lineHeight: '2.25',
      color: '#666',
      marginBottom: isMobile ? '15px' : '25px', // Adjust margin based on device
    },
    emailLink: {
      fontWeight: 'bold',
      color: '#777',
      textDecoration: 'none',
      transition: 'filter 0.3s',
      fontSize: isMobile ? '15px' : '17px', // Ensure consistency
    },
    logoContainer: {
      marginTop: isMobile ? '15px' : '30px', // Adjust margin based on device
      textAlign: 'left',
    },
    logo: {
      width: isMobile ? '50px' : '55px', // Adjust size on mobile
      height: isMobile ? '50px' : '55px',
      fill: '#666',
      cursor: 'pointer',
      marginLeft: isMobile ? '-10px' : '-15px', // Adjust margin on mobile
      paddingLeft: 0,
    },
    link: {
      textDecoration: 'none',
      color: '#666',
    },
    visited: {
      color: 'inherit',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.contactText}>CONTACT.</div>
      </div>
      <div style={styles.textBlock}>
        You may reach us at{' '}
        <a
          href="mailto:hello@handyco.tech"
          style={styles.emailLink}
          onMouseOver={(e) => (e.currentTarget.style.filter = 'blur(2px)')}
          onMouseOut={(e) => (e.currentTarget.style.filter = 'none')}
        >
          hello@handyco.tech
        </a>.
      </div>
      <div style={styles.logoContainer}>
        <Link
          to="/"
          style={styles.link}
          onMouseDown={(e) => (e.currentTarget.style.color = 'inherit')}
        >
          <div style={{ display: 'inline-block' }}>
            <Logo style={styles.logo} />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Contact;